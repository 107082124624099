html {
    box-sizing: border-box;
    font-size: 100% !important;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
/* Extract from normalize.css by Nicolas Gallagher and Jonathan Neal git.io/normalize */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
progress,
video {
    display: inline-block;
}
progress {
    vertical-align: baseline;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
[hidden],
template {
    display: none;
}
a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
    outline-width: 0;
}
abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}
dfn {
    font-style: italic;
}
mark {
    background: #ff0;
    color: #000;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
figure {
    margin: 1em 40px;
}
img {
    border-style: none;
}
svg:not(:root) {
    overflow: hidden;
}
code,
kbd,
pre,
samp {
    font-family: monospace;
    font-size: 1em;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
button,
input,
select,
textarea {
    font: inherit;
    margin: 0;
}
optgroup {
    font-weight: bold;
}
button,
input {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}
fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}
textarea {
    overflow: auto;
}
[type="checkbox"],
[type="radio"] {
    padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

html,
body {
    height: 100%;
    margin: 0;
    font-size: 100%;
}
body {
    font-family: "UniversNextForHSBC", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    color: #fff;
    background-color: #1d262c;
}
body.modal--open {
    overflow-y: hidden;
}
#root {
    height: 100%;
}

@font-face {
    font-family: "UniversNextForHSBC";
    src: url("./fonts/UniversNext/UniversNextforHSBCW02-Bd.eot"); /* IE9 Compat Modes */
    src: url("./fonts/UniversNext/UniversNextforHSBCW02-Bd.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("./fonts/UniversNext/UniversNextforHSBCW02-Bd.woff")
            format("woff"),
        /* Pretty Modern Browsers */
            url("./fonts/UniversNext/UniversNextforHSBCW02-Bd.ttf")
            format("truetype"); /* Safari, Android, iOS */
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "UniversNextForHSBC";
    src: url("./fonts/UniversNext/UniversNextforHSBCW02-Md.eot"); /* IE9 Compat Modes */
    src: url("./fonts/UniversNext/UniversNextforHSBCW02-Md.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("./fonts/UniversNext/UniversNextforHSBCW02-Md.woff")
            format("woff"),
        /* Pretty Modern Browsers */
            url("./fonts/UniversNext/UniversNextforHSBCW02-Md.ttf")
            format("truetype"); /* Safari, Android, iOS */
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "UniversNextForHSBC";
    src: url("./fonts/UniversNext/UniversNextforHSBCW02-Lt.eot"); /* IE9 Compat Modes */
    src: url("./fonts/UniversNext/UniversNextforHSBCW02-Lt.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("./fonts/UniversNext/UniversNextforHSBCW02-Lt.woff")
            format("woff"),
        /* Pretty Modern Browsers */
            url("./fonts/UniversNext/UniversNextforHSBCW02-Lt.ttf")
            format("truetype"); /* Safari, Android, iOS */
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "UniversNextForHSBC";
    src: url("./fonts/UniversNext/UniversNextforHSBCW02-Rg.eot"); /* IE9 Compat Modes */
    src: url("./fonts/UniversNext/UniversNextforHSBCW02-Rg.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("./fonts/UniversNext/UniversNextforHSBCW02-Rg.woff")
            format("woff"),
        /* Pretty Modern Browsers */
            url("./fonts/UniversNext/UniversNextforHSBCW02-Rg.ttf")
            format("truetype"); /* Safari, Android, iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "UniversNextForHSBC";
    src: url("./fonts/UniversNext/UniversNextforHSBCW02-Th.eot"); /* IE9 Compat Modes */
    src: url("./fonts/UniversNext/UniversNextforHSBCW02-Th.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("./fonts/UniversNext/UniversNextforHSBCW02-Th.woff")
            format("woff"),
        /* Pretty Modern Browsers */
            url("./fonts/UniversNext/UniversNextforHSBCW02-Th.ttf")
            format("truetype"); /* Safari, Android, iOS */
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "HSBCIcon-Font";
    src: url("./fonts/HSBCIconFont/HSBCIcon-Font.eot");
    src: url("./fonts/HSBCIconFont/HSBCIcon-Font.eot")
            format("embedded-opentype"),
        url("./fonts/HSBCIconFont/HSBCIcon-Font.woff") format("woff"),
        url("./fonts/HSBCIconFont/HSBCIcon-Font.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.skip--to-title {
    position: absolute;
    left: -99999px;
    top: 0px;
}

.icon {
    font-family: "HSBCIcon-Font",sans-serif;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon--security-lock:before {
    content: "\f02a";
}

.icon--phone:before {
    content: "\f005";
}

.icon--global:before {
    content: "\f037";
}
.icon--agree:before {
    content: "\f00A";
}
.icon--chevron-down-small:before {
    content: "\E003";
}

.icon--chevron-down:before {
    content: "\F055";
}

.icon--chevron-up:before {
    content: "\f056";
}

.icon--chevron-left:before {
    content: "\F058";
}

.icon--chevron-right:before {
    content: "\F057";
}

.icon--search:before {
    content: "\f007";
}

.icon--alert:before {
    content: "\f017";
}

.icon--stock-down:before {
    content: "\f072";
}

.icon--stock-up:before {
    content: "\f071";
}

.icon--triangle-error:before {
    content: "\f069";
}

.icon--delete:before {
    content: "\f002";
}
.icon--circle-delete-solid:before {
    content: "\e007";
}

.icon--circle-error-solid:before {
    content: "\e009";
}

.icon--circle-confirmation-solid:before {
    content: "\e008";
}

.icon--do-not-view:before {
    content: "\e020";
}

.icon--view:before {
    content: "\e063";
}

.showbox {
    font-family: UniversNextForHSBC,sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    padding: 5%;
    background-color: #f6f6f6;
    color: #444444;
}

.showbox.darkbg {
    background-color: #4d6474;
    color: #f0f2f3;
    top: 200px;
}

.loader {
    position: relative;
    margin: 0 auto;
    width: 50px;
    top: 50%;
    margin-top: -25px;
}

.loader:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.circular {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: square;
    stroke: #444444;
}
.showbox.darkbg .path {
    stroke: #f0f2f3;
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
